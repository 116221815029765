<template>
  <div 
    class="recognition-card" 
    :class="{'recog-selected': aiMode === 'lpr' }" 
    @click="viewLprTask()"
  >
    <div class="title">
      <img src="@/assets/icons/LPR.svg" alt="">
      <div class="name">{{ $t('ai_license_plate_recognition')/*車牌辨識*/ }}</div>
    </div>
    <!-- <div v-if="data.id === -99" class="status">{{ $t('ai_task_add') }}</div>
    <div v-else-if="mode === 'edit' && data.id >= 0 && data.id === taskId" class="status">{{ $t('ai_task_edit') }}</div>
    <div v-else-if="data.status === 0" class="status">{{ $t(`ai_status_${data.status}`) }}</div>
    <div v-else class="status error">{{ $t(`ai_status_${data.status}`) }}</div> -->
    <div class="item">
      <div class="label">{{ $t('ai_update_time')/*更新時間*/ }}</div>
      <!-- <div 
        class="value time" 
        :class="{ orange: data.timeSinceLastUpdated > 30 && data.timeSinceLastUpdated <= 60, red: data.timeSinceLastUpdated > 60 }">
        {{ timeAgo(data.updatedTime, data.timeSinceLastUpdated) }}
      </div> -->
      <div v-show="aiMode === 'lpr'" class="icons">
        <div v-if="permissionV2.deviceCtrl > 1 && permissionV2.device > 1 && mode === 'view'" class="icon" @click.stop="goEditMode">
          <img src="@/assets/icons/pen.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { diffSecs, timeAgo } from '@/utils/lib.js'

export default {
  name: 'TaskCardLpr',
  data() {
    return {}
  },
  computed: {
    ...mapState(['permissionV2']),
    ...mapState('aibox', ['aiMode']),
    ...mapState('aiboxLpr', ['mode']),
    ...mapGetters('aiboxLpr', ['isSettingReady']),
  },
  methods: {
    ...mapMutations('aibox', ['updateAiMode']),
    ...mapMutations('aiboxLpr', ['updateMode']),
    diffSecs,
    timeAgo,
    viewLprTask() {
      this.updateAiMode('lpr')
    },
    goEditMode() {
      if (!this.isSettingReady) return
      this.updateMode('edit')
    },
  }
}
</script>

<style lang="scss" scoped>
.recognition-card {
  background: #4A5C7866;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 8px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  cursor: pointer;
}

.recog-selected {
  background: #4A5C78;
  border: 3px solid #FFE99F;
}

.title {
  width: 60%;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  display: flex;
  justify-content: start;
}

.reg-title img, .title img, .option img {
  margin-right: 12px;
  position: relative;
  top: 2px;
}

.title .name {
  width: 60%;
}

.status {
  position: absolute;
  top: 12px;
  right: 0;
  max-width: 150px;
  font-size: 20px;
  font-weight: 300;
  line-height: 23px;
  padding: 6px 12px;
  background: #6E7D93;
  border-radius: 9px 0px 0px 9px;
}

.error {
  background: #F94144;
}

.item {
  display: flex;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  margin-top: 6px;
  position: relative;
}

.item .label {
  width: 40%;
  color: #6E7D93;
}

.item .value {
  width: 60%;
  word-break: break-all;
}

.time {
  color: #8CC24D;
}

.orange {
  color: #D8A848;
}

.red {
  color: #F94144;
}

.icons {
  display: flex;
  position: absolute;
  right: 0px;
  top: -3px;
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.icon:hover {
  background: #FFFFFF33;
}

.icon img {
  width: 20px;
  height: 20px;
}

.icons .icon:first-of-type {
  margin-right: 4px;
}
</style>